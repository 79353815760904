import React from 'react';

import BottomHeader from './bottomHeader/BottomHeader';
import SessionEnding from './sessionEnding/SessionEnding';
import Shield from '../Shield';
import TopHeader from './topHeader/TopHeader';
import UserIcon from '../../icons/UserIcon';

import style from './Header.module.css';
import useHeader from './UseHeader';

const Header = ({ showSessionEnding, setShowSessionEnding, calculateShowSessionEnding }) => {
  const {onLogoutClicked, isUnregisteredUser} = useHeader();

  if (isUnregisteredUser === true) {
    return (
      <header>
        <Shield className={style.Shield} />
        <nav className={style.TopHeader}>
          <ul>
            <li><button onClick={onLogoutClicked}><UserIcon tooltip="Logout" />&nbsp;Logout</button></li>
          </ul>
        </nav>
        <div className={[style.LargeBottomHeader, 'hidden-xs hidden-sm'].join(' ')}>
          <div className={style.Primary}>
            &nbsp;
          </div>
        </div>
        <div className={[style.SmallBottomHeader, 'visible-xs visible-sm'].join(' ')}>
          &nbsp;
        </div>
      </header>
    );
  }

  return (
    <header>
      <Shield className={style.Shield} />
      <TopHeader />
      <BottomHeader />
      {showSessionEnding === true && (
        <SessionEnding setShowSessionEnding={setShowSessionEnding} calculateShowSessionEnding={calculateShowSessionEnding} />
      )}
    </header>
  );
};

export default Header;
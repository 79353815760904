//import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import { PaymentsHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const getShoppingCarts = (personId, state, setState) => {
  //if (state.isArrayLoading !== true && !state.message) {
  if (state.isObjLoading !== true && !state.message) {
    let newState = {
      ...state,
      //isArrayLoading: true,
      isObjLoading: true,
      //isArrayLoaded: false,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    // const url = personId
    //   ? `/ShoppingCart/List/${encodeURIComponent(personId)}`
    //   : '/ShoppingCart/List';
    const url = '/Payments/Cart/Count'
    //CommonHttpHelper(url, 'GET')
    PaymentsHttpHelper(url, 'GET')
      //.then((arrayData) => {
      .then((objData) => {
        //if (!arrayData) {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //const hasUnpaidTransactions = arrayData?.some(x => x.txnStatus.toUpperCase() === 'NOT PAID') || false;
          const hasUnpaidTransactions = objData?.count > 0;
          newState = {
            ...newState,
            //isArrayLoading: false,
            isObjLoading: false,
            //isArrayLoaded: true,
            isObjLoaded: true,
            hasUnpaidTransactions,
            //arrayData,
            objData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          //isArrayLoading: false,
          isObjLoading: false,
          //isArrayLoaded: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default getShoppingCarts;
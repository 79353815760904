import { useNavigate } from '../../wrappers/ReactRouterDom';

import { navigateToCrossUiRoute } from '../../utils/FormatCrossUiRoute';

import useGlobalRoutesData from '../security/UseGlobalRoutesData';

const useCrossUINavigation = () => {
  const { getGlobalRoute } = useGlobalRoutesData();
  const navigate = useNavigate();

  const navigateToRouteName = (routeName) => {
    const routeObj = getGlobalRoute(routeName);
    navigateToCrossUiRoute(routeObj.uIProjectName, routeObj.route, navigate);
  };

  const navigateToLandingPage = () => {
    const route = getGlobalRoute('HUB_LANDING_PAGES');
    if (route) {
      let domain = import.meta.env[`VITE_${route.uIProjectName.toUpperCase().replace('-', '_')}_DOMAIN`];
      if (domain === 'self') {
        domain = import.meta.env.VITE_BASE_DOMAIN;
      }
      const url = `https://${domain}`;
      window.location = url;
    }
    else {
      navigateToRouteName('HUB_LANDING_PAGES');
    }
  };

  return { navigateToLandingPage, navigateToRouteName };
};

export default useCrossUINavigation;
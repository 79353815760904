import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import useNavRoutes from '../common/state/security/UseNavRoutes';

import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';

import LandingPage from './views/landingPage/LandingPage';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path="/" element={<Fragment><Outlet /></Fragment>}>
        <Route path={navRoutes.LANDING_ROOT?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANDING_ROOT}
              editElement={<LandingPage />} />
          }
        />
        <Route path="500" element={<UnexpectedError />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
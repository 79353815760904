import { useEffect, useState } from 'react';
import { useSearchParams } from '../../../../../common/wrappers/ReactRouterDom';
import Constants from '../../../../utils/Constants';
import usePingData from '../../../../../common/state/ping/UsePingData';
import useToken from '../../../../state/security/UseToken';

const STEP_LOGIN_STEP_1 = 'STEP_LOGIN_STEP_1';
const STEP_LOGIN_STEP_2 = 'STEP_LOGIN_STEP_2';
const STEP_LOGIN_STEP_3 = 'STEP_LOGIN_STEP_3';
const ERROR_MESSAGE = 'An error occurred. Please try again later.';

const useLogin = (onModalCanceled, setShowSessionEnding, calculateShowSessionEnding) => {
  const [searchParams,] = useSearchParams();
  
  const {
    loginState, setLoginState, login,
    oneTimePasswordCheckState, setOneTimePasswordCheckState, oneTimePasswordCheck,
    passwordResetState, setPasswordResetState, passwordReset
  } = usePingData();
  const { setStoredToken } = useToken();

  const [sharedFormState, setSharedFormState] = useState({
    step: STEP_LOGIN_STEP_1
  });

  const [loginFormState, setLoginFormState] = useState({
    error: '',
    username: sessionStorage.getItem(Constants.USERNAME),
    password: '',
    passwordError: false,
    showPassword: false,
    href: '',
    device: '',
    isServerSide: false,
    verificationCode: '',
    verificationCodeError: false,
    oneTimePassword: '',
    oneTimePasswordError: false,
    currentPassword: '',
    currentPasswordError: false,
    showCurrentPassword: false,
    newPassword: '',
    newPasswordError: false,
    newPassword10Characters: false,
    newPassword1SpecialCharacter: false,
    newPassword1Number: false,
    newPassword1UppercaseCharacter: false,
    newPassword1LowercaseCharacter: false,
    newPasswordNotEqualToUsername: false,
    showNewPassword: false,
    submitButtonIsDisabled: true
  });

  // eslint-disable-next-line no-useless-escape
  const includesSpecialCharacterRegex = /[!"#$%&'()*+,-.\/:;<=>?@[\]\\^_`{|}~]/g;
  const includesNumberRegex = /[0-9]/g;
  const includesLowerCaseRegex = /[a-z]/g;
  const includesUpperCaseRegex = /[A-Z]/g;
  const validatePassword = (username, password) => {
    if (!password || username === password || password.includes(' ')) {
      return false;
    } else if (password.length < 10) {
      return false;
    } else if (includesNumberRegex.test(password) !== true) {
      return false;
    } else if (includesLowerCaseRegex.test(password) !== true) {
      return false;
    } else if (includesUpperCaseRegex.test(password) !== true) {
      return false;
    } else if (includesSpecialCharacterRegex.test(password) !== true) {
      return false;
    }

    return true;
  };

  // login

  const handleLoginPasswordChange = (value) => {
    setLoginFormState({
      ...loginFormState,
      password: value,
      passwordError: !validPassword(value)
    });
  }

  const toggleLoginShowPassword = (e) => {
    setLoginFormState({
      ...loginFormState,
      showPassword: !loginFormState.showPassword
    });
  }

  const onLoginClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const isValidPassword = validPassword(loginFormState.password);

    if (isValidPassword === true) {
      login(loginFormState.username, loginFormState.password, null, null);
    }

    setLoginFormState({
      ...loginFormState,
      passwordError: !isValidPassword
    });
  };

  useEffect(() => {
    if (loginState.message !== '' && loginState.message !== 'Loading...') {
      if (loginFormState.error !== ERROR_MESSAGE) {
        setLoginFormState({
          ...loginFormState,
          error: ERROR_MESSAGE
        });
      }
      setLoginState({
        ...loginState,
        isObjLoaded: false,
        message: ''
      });
    }
    else if (loginState.isObjLoaded === true) {
      // otp required?
      if (loginState.objData.oneTimePasswordRequired === true) {
        // server-side login
        if (sharedFormState.step !== STEP_LOGIN_STEP_2) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_2
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: loginState.objData.href,
            device: loginState.objData.device,
            isServerSide: true
          });
        }
      }
      else if (loginState.objData.status === 'OTP_REQUIRED') {
        // client-side login
        var device = '';
        var selectedDeviceId = loginState.objData.selectedDevice?.id;
        for (var i = 0; i < loginState.objData._embedded?.devices?.length; i++) {
          if (loginState.objData._embedded.devices[i].id === selectedDeviceId) {
            device = loginState.objData._embedded.devices[i].email || loginState.objData._embedded.devices[i].phone;
            break;
          }
        }
        if (sharedFormState.step !== STEP_LOGIN_STEP_2) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_2
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: loginState.objData._links['otp.check'].href,
            device: device,
            isServerSide: false
          });
        }
      }

      // account locked out?
      else if (loginState.objData.accountLockedOut === true) {
        // server-side login
        const minutes = Math.ceil(loginState.objData.secondsUntilUnlock / 60);
        const error = `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`;
        if (loginFormState.error !== error) {
          setLoginFormState({
            ...loginFormState,
            error: error
          });
        }
      }
      else if (loginState.objData.status === 'FAILED' && loginState.objData.error?.code === 'PASSWORD_LOCKED_OUT') {
        // client-side login
        const minutes = Math.ceil(loginState.objData.error.secondsUntilUnlock / 60);
        const error = `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`;
        if (loginFormState.error !== error) {
          setLoginFormState({
            ...loginFormState,
            error: error
          });
        }
      }

      // must change password?
      else if (loginState.objData.mustChangePassword === true) {
        // server-side login
        if (sharedFormState.step !== STEP_LOGIN_STEP_3) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_3
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: loginState.objData.href,
            isServerSide: true
          });
        }
      }
      else if (loginState.objData.status === 'MUST_CHANGE_PASSWORD' || loginState.objData.status === 'PASSWORD_EXPIRED') {
        // client-side login
        if (sharedFormState.step !== STEP_LOGIN_STEP_3) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_3
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: loginState.objData._links['password.reset'].href,
            isServerSide: false
          });
        }
      }

      // invalid username/password
      else if (loginState.objData.accessToken === null) {
        // server-side login
        if (loginFormState.error !== 'The username and/or password is invalid.') {
          setLoginFormState({
            ...loginFormState,
            error: 'The username and/or password is invalid.'
          });
        }
      }
      else if (loginState.objData.code === 'INVALID_DATA') {
        // client-side login
        if (loginFormState.error !== 'The username and/or password is invalid.') {
          setLoginFormState({
            ...loginFormState,
            error: 'The username and/or password is invalid.'
          });
        }
      }

      // success!
      else {
        setStoredToken(loginState.objData.accessToken, loginState.objData.refreshToken, loginState.objData.expirationDatetime, loginState.objData.username);
        calculateShowSessionEnding();
        onModalCanceled();
      }
      setLoginState({
        ...loginState,
        isObjLoaded: false,
        message: ''
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, loginFormState]);

  const handleLoginOneTimePasswordChange = (value) => {
    setLoginFormState({
      ...loginFormState,
      oneTimePassword: value,
      oneTimePasswordError: !validOneTimePassword(value)
    });
  }

  const onLoginSubmitClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const isValidOneTimePassword = validOneTimePassword(loginFormState.oneTimePassword);

    if (isValidOneTimePassword === true) {
      oneTimePasswordCheck(loginFormState.href, loginFormState.oneTimePassword, loginFormState.isServerSide);
    }

    setLoginFormState({
      ...loginFormState,
      oneTimePasswordError: !isValidOneTimePassword
    });
  }

  useEffect(() => {
    if (oneTimePasswordCheckState.message !== '' && oneTimePasswordCheckState.message !== 'Loading...') {
      if (loginFormState.error !== ERROR_MESSAGE) {
        setLoginFormState({
          ...loginFormState,
          error: ERROR_MESSAGE
        });
      }
      setOneTimePasswordCheckState({
        ...oneTimePasswordCheckState,
        isObjLoaded: false,
        message: ''
      });
    }
    else if (oneTimePasswordCheckState.isObjLoaded === true) {
      // invalid one-time password
      if (oneTimePasswordCheckState.objData.accessToken === null) {
        // server-side one-time password check
        if (loginFormState.error !== 'The one-time password is invalid.') {
          setLoginFormState({
            ...loginFormState,
            error: 'The one-time password is invalid.'
          });
        }
      }
      else if (oneTimePasswordCheckState.objData.code === 'INVALID_DATA') {
        // client-side one-time password check
        if (loginFormState.error !== 'The one-time password is invalid.') {
          setLoginFormState({
            ...loginFormState,
            error: 'The one-time password is invalid.'
          });
        }
      }

      // success!
      else {
        setStoredToken(oneTimePasswordCheckState.objData.accessToken, oneTimePasswordCheckState.objData.refreshToken, oneTimePasswordCheckState.objData.expirationDatetime, oneTimePasswordCheckState.objData.username);
        calculateShowSessionEnding();
        onModalCanceled();
      }
      setOneTimePasswordCheckState({
        ...oneTimePasswordCheckState,
        isObjLoaded: false,
        message: ''
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneTimePasswordCheckState, loginFormState]);

  const handleLoginCurrentPasswordChange = (value) => {
    setLoginFormState({
      ...loginFormState,
      currentPassword: value,
      currentPasswordError: !validCurrentPassword(value)
    });
  }

  const toggleLoginShowCurrentPassword = (e) => {
    setLoginFormState({
      ...loginFormState,
      showCurrentPassword: !loginFormState.showCurrentPassword
    });
  }

  const handleLoginNewPasswordChange = (value) => {
    setLoginFormState({
      ...loginFormState,
      newPassword: value,
      newPasswordError: !validNewPassword(value),
      newPassword10Characters: password10Characters(value),
      newPassword1SpecialCharacter: password1SpecialCharacter(value),
      newPassword1Number: password1Number(value),
      newPassword1UppercaseCharacter: password1UppercaseCharacter(value),
      newPassword1LowercaseCharacter: password1LowercaseCharacter(value),
      newPasswordNotEqualToUsername: passwordNotEqualToUsername(value, loginFormState.username),
      submitButtonIsDisabled: validatePassword(loginFormState.username, value) === false
    });
  }

  const toggleLoginShowNewPassword = (e) => {
    setLoginFormState({
      ...loginFormState,
      showNewPassword: !loginFormState.showNewPassword
    });
  }

  const onLoginChangePasswordClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const isValidCurrentPassword = validCurrentPassword(loginFormState.currentPassword);
    const isValidNewPassword = validNewPassword(loginFormState.newPassword);

    if (isValidCurrentPassword === true && isValidNewPassword === true) {
      passwordReset(loginFormState.href, loginFormState.currentPassword, loginFormState.newPassword, loginFormState.isServerSide);
    }

    setLoginFormState({
      ...loginFormState,
      currentPasswordError: !isValidCurrentPassword,
      newPasswordError: !isValidNewPassword
    });
  }

  useEffect(() => {
    if (passwordResetState.message !== '' && passwordResetState.message !== 'Loading...') {
      if (loginFormState.error !== ERROR_MESSAGE) {
        setLoginFormState({
          ...loginFormState,
          error: ERROR_MESSAGE
        });
      }
      setPasswordResetState({
        ...passwordResetState,
        isObjLoaded: false,
        message: ''
      });
    }
    else if (passwordResetState.isObjLoaded === true) {
      // otp required?
      if (passwordResetState.objData.oneTimePasswordRequired === true) {
        // server-side password reset
        if (sharedFormState.step !== STEP_LOGIN_STEP_2) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_2
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: passwordResetState.objData.href,
            device: passwordResetState.objData.device,
            isServerSide: true
          });
        }
      }
      else if (passwordResetState.objData.status === 'OTP_REQUIRED') {
        // client-side password reset
        var device = '';
        var selectedDeviceId = passwordResetState.objData.selectedDevice?.id;
        for (var i = 0; i < passwordResetState.objData._embedded?.devices?.length; i++) {
          if (passwordResetState.objData._embedded.devices[i].id === selectedDeviceId) {
            device = passwordResetState.objData._embedded.devices[i].email || passwordResetState.objData._embedded.devices[i].phone;
            break;
          }
        }
        if (sharedFormState.step !== STEP_LOGIN_STEP_2) {
          setSharedFormState({
            ...sharedFormState,
            step: STEP_LOGIN_STEP_2
          });
          setLoginFormState({
            ...loginFormState,
            error: '',
            href: passwordResetState.objData._links['otp.check'].href,
            device: device,
            isServerSide: false
          });
        }
      }

      // invalid password reset
      else if (passwordResetState.objData.errorMessage !== null) {
        // server-side password reset
        if (loginFormState.error !== passwordResetState.objData.errorMessage) {
          setLoginFormState({
            ...loginFormState,
            error: passwordResetState.objData.errorMessage
          });
        }
      }
      else if (passwordResetState.objData.code === 'INVALID_DATA') {
        // client-side password reset
        let error = null;

        const code = passwordResetState.objData.details?.first().code;
        const target = passwordResetState.objData.details[0]?.target;

        if (code === 'INVALID_VALUE'
            && target === 'currentPassword') {
            error = 'The provided current password is invalid.';
        }
        else if (code === 'INVALID_VALUE'
            && target === 'newPassword') {
            const unsatisfiedRequirement = passwordResetState.objData.details[0]?.innerError?.unsatisfiedRequirements[0];

            if (unsatisfiedRequirement == 'excludesCommonlyUsed') {
                error = 'The provided new password (or a variant of that new password) was found in a list of prohibited passwords.';
            }
            else if (unsatisfiedRequirement === 'excludesProfileData') {
                error = 'The provided new password was found in another attribute in the user entry.';
            }
            else if (unsatisfiedRequirement === 'history') {
                error = 'The provided new password cannot be the same as the current password or any password in the user\'s password history.';
            }
            else if (unsatisfiedRequirement === 'notSimilarToCurrent') {
                error = 'The provided new password cannot be similar to the current password.';
            }
            else
            {
                error = 'An unknown error occurred. Please contact USA Swimming for assistance.';
            }
        }
        else if (code === 'PASSWORD_TOO_YOUNG') {
            error = 'The password cannot be changed because it has not been long enough since the last password change.';
        }
        else
        {
            error = 'An unknown error occurred. Please contact USA Swimming for assistance.';
        }
        if (loginFormState.error !== error) {
          setLoginFormState({
            ...loginFormState,
            error: error
          });
        }
      }

      // success!
      else {
        setStoredToken(passwordResetState.objData.accessToken, passwordResetState.objData.refreshToken, passwordResetState.objData.expirationDatetime, passwordResetState.objData.username);
        calculateShowSessionEnding();
        onModalCanceled();
      }
      setPasswordResetState({
        ...passwordResetState,
        isObjLoaded: false,
        message: ''
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordResetState, loginFormState]);

  // cancel

  const onCancelClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setSharedFormState({
      ...sharedFormState,
      step: STEP_LOGIN_STEP_1
    });

    setLoginFormState({
      ...loginFormState,
      error: '',
      password: '',
      passwordError: false,
      showPassword: false,
      href: '',
      device: '',
      isServerSide: false,
      verificationCode: '',
      verificationCodeError: false,
      oneTimePassword: '',
      oneTimePasswordError: false,
      currentPassword: '',
      currentPasswordError: false,
      showCurrentPassword: false,
      newPassword: '',
      newPasswordError: false,
      newPasswordError: false,
      newPassword10Characters: false,
      newPassword1SpecialCharacter: false,
      newPassword1Number: false,
      newPassword1UppercaseCharacter: false,
      newPassword1LowercaseCharacter: false,
      newPasswordNotEqualToUsername: false,
      showNewPassword: false,
      submitButtonIsDisabled: true
    });
  }

  // validation

  const password10Characters = (value) => {
    return value.length >= 10;
  }

  const password1SpecialCharacter = (value) => {
    const characters = '~!@#$%^&*()-_=+[]{}|;:,.<>/?';
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value[i]) > -1) {
        return true;
      }
    }
    return false;
  }

  const password1Number = (value) => {
    const characters = '0123456789';
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value[i]) > -1) {
        return true;
      }
    }
    return false;
  }

  const password1UppercaseCharacter = (value) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value[i]) > -1) {
        return true;
      }
    }
    return false;
  }

  const password1LowercaseCharacter = (value) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value[i]) > -1) {
        return true;
      }
    }
    return false;
  }

  const passwordNotEqualToUsername = (value, username) => {
    return value !== username;
  }

  const validCurrentPassword = (value) => {
    return value?.trim().length > 0;
  }

  const validNewPassword = (value) => {
    return value?.trim().length > 0;
  }

  const validOneTimePassword = (value) => {
    return value?.trim().length > 0;
  }

  const validPassword = (value) => {
    return value?.trim().length > 0;
  }

  return {
    loginState,
    oneTimePasswordCheckState,
    passwordResetState,
    sharedFormState,
    loginFormState,

    handleLoginPasswordChange,
    toggleLoginShowPassword,
    onLoginClicked,
    handleLoginOneTimePasswordChange,
    onLoginSubmitClicked,
    handleLoginCurrentPasswordChange,
    toggleLoginShowCurrentPassword,
    handleLoginNewPasswordChange,
    toggleLoginShowNewPassword,
    onLoginChangePasswordClicked,

    onCancelClicked
  };
}

export default useLogin;
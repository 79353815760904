import { useContext, useEffect } from 'react';
import { useNavigate } from '../../../../wrappers/ReactRouterDom';

import useSecurityData from '../../../../state/security/UseSecurityData';
import useGlobalRoutesData from '../../../../state/security/UseGlobalRoutesData';
// import useShoppingCartData from '../../../../state/shoppingCart/UseShoppingCartData';
import useCartItemData from '../../../../state/cartItem/UseCartItemData';
import usePaymentsData from '../../../../state/payments/UsePaymentsData';

import { PaymentsStateContext } from '../../../../state/payments/PaymentsContextProvider';

import { navigateToCrossUiRoute } from '../../../../utils/FormatCrossUiRoute';

const useTopHeader = () => {
  const navigate = useNavigate();
  const { securityState, login, logout } = useSecurityData();
  // const { shoppingCartState, getShoppingCarts } = useShoppingCartData();
  const { cartItemState, clearCartItemObjData } = useCartItemData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { getCartCountState, getCartCount } = usePaymentsData();
  const { cartChanged, setCartChanged } = useContext(PaymentsStateContext);
  const menuItems = Array.isArray(securityState?.userInfo?.taxonomies)
    ? securityState?.userInfo?.taxonomies.find(t => t.taxonomyName === 'TopMenu')?.taxonomyLinks || []
    : [];


  const formatName = (userInfo) => {
    if (userInfo?.firstName && userInfo?.firstName !== 'Anonymous' && userInfo?.firstName !== 'Unregistered') {
      return userInfo?.firstName;
    }

    return '';
  };

  const userName = formatName(securityState?.userInfo);
  const showLogin = !userName && !securityState?.message;

  const onShoppingCartClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onLoginClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    login();
  };

  const onSettingsClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigateToCrossUiRoute('hub-ui', '/accountmanagement', navigate);
  }

  const onLogoutClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    logout();
  };

  useEffect(() => {
    if (securityState?.isObjLoaded === true
      && securityState?.userInfo?.isLoggedIn === true
      && cartItemState.isSaved === true) {
      clearCartItemObjData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState, /*shoppingCartState,*/ cartItemState]);

  useEffect(() => {
    if (securityState?.isObjLoaded === true
      && securityState?.userInfo?.isLoggedIn === true
      && !getCartCountState?.isObjLoaded) {
      getCartCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState, getCartCountState]);

  useEffect(() => {
    if (cartChanged === true) {
      getCartCount();
      setCartChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartChanged]);

  return {
    menuItems,
    // hasUnpaidTransactions: shoppingCartState?.hasUnpaidTransactions === true,
    isLoggedIn: securityState?.userInfo?.isLoggedIn === true,
    cartCount: getCartCountState?.objData?.count || 0,
    userName,
    showLogin,
    onShoppingCartClicked,
    onLoginClicked,
    onSettingsClicked,
    onLogoutClicked,
    getCartCountState
  };
};

export default useTopHeader;
import { useState } from 'react';

import PaymentsData from './PaymentsData';

const usePaymentsData = () => {
  const [getCartCountState, setGetCartCountState] = useState({});

  const getCart = (orgUnitId, itemSubsystemId, itemContract, referenceId) => {
    return PaymentsData.getCart(orgUnitId, itemSubsystemId, itemContract, referenceId);
  }

  const deleteItem = (orgUnitId, itemSubsystemId, itemContract, referenceId, id) => {
    return PaymentsData.deleteItem(orgUnitId, itemSubsystemId, itemContract, referenceId, id);
  }

  const checkout = (orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn) => {
    return PaymentsData.checkout(orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn);
  }

  const checkoutByCheck = (orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, personId) => {
    return PaymentsData.checkoutByCheck(orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, personId);
  }

  const downloadReceipt = (paymentId, showDetail) => {
    return PaymentsData.downloadReceipt(paymentId, showDetail);
  }

  //for admin version of download receipt
  const downloadReceiptAdmin = (receiptNumber, showDetail) => {
    return PaymentsData.downloadReceiptAdmin(receiptNumber, showDetail);
  }

  const getCartCount = (orgUnitId, itemSubsystemId, itemContract, referenceId) => {
    return PaymentsData.getCartCount(orgUnitId, itemSubsystemId, itemContract, referenceId, getCartCountState, setGetCartCountState);
  }

  return {
    getCart,
    deleteItem,
    checkout,
    checkoutByCheck,
    downloadReceipt,
    downloadReceiptAdmin,
    getCartCount,
    getCartCountState
  };
};

export default usePaymentsData;
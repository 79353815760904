import React, { createContext, useState } from 'react';

import PurchaseItemData from './PurchaseItemData';

export const PurchaseItemStateContext = createContext();

const PurchaseItemContextProvider = ({ children }) => {
  const stateHook = useState(PurchaseItemData.INITIAL_STATE);

  return (
    <PurchaseItemStateContext.Provider value={stateHook}>
      {children}
    </PurchaseItemStateContext.Provider>
  );
};

export default PurchaseItemContextProvider;
import { BASIC_INITIAL_STATE, NO_DATA_MESSAGE, SecurityHttpHelper } from '../../utils/HttpHelper';

const getGlobalRoutesData = (state, setState) => {
  if (state.isArrayLoading !== true && state.isArrayLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/AppRoute/ProjectRouteInfo`;
    SecurityHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const GlobalRoutesData = {INITIAL_STATE: {...BASIC_INITIAL_STATE}, getGlobalRoutesData};

export default GlobalRoutesData;
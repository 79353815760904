//import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import { PaymentsHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const getOpenOmrCartItems = (state, setState) => {
  if (state.isObjLoading !== true && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    
    //const url = '/Ecommerce/PurchaseItem/ActiveOMR';
    //const url = '/CartItem/ActiveOMRCartItems';
    const url = '/Payments/Cart/Count?ItemSubsystemId=4'
    //CommonHttpHelper(url, 'GET')
    PaymentsHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default getOpenOmrCartItems;
import React from 'react';

import useCountryCombobox from './UseCountryCombobox';

import Combobox from '../Combobox';

const CountryCombobox = ({
  label, name, valueToMatch, error, message, onChange
}) => {
  const { countryData } = useCountryCombobox();

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={countryData}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default CountryCombobox;
import Constants from './Constants';

const getAccessToken = () => {
  let result = '';
  try {
    const expirationDatetime = new Date(sessionStorage.getItem(Constants.EXPIRATION_DATETIME));
    const now = new Date();
    if (isNaN(expirationDatetime) === true /* usa swimming app */ || now < expirationDatetime) {
      result = sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME);
    }
  }
  catch (e) {
  }
  return result;
}

const getRefreshToken = () => {
  let result = '';
  try {
    const expirationDatetime = new Date(sessionStorage.getItem(Constants.EXPIRATION_DATETIME));
    const now = new Date();
    if (now < expirationDatetime) {
      result = sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME);
    }
  }
  catch (e) {
  }
  return result;
}

const jsonApiHelper = async (url, method, payload) => {
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'Usas-Refresh-Token': `${getRefreshToken()}`
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      if (response.ok || response.status === 404) {
        processTokens(response);
        return response.status === 404 ? undefined : response.json();
      } else if (response.status === 400) {
        return response.text().then(function (text) {
          throw new Error(text);
        });
      } else if (response.status >= 500) {
        throw new Error(CORS_ERROR_MESSAGE);
      } else {
        throw new Error(API_ERROR_MESSAGE);
      }
    });
};

const filePostApiHelper = async (url, payload) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Usas-Refresh-Token': `${getRefreshToken()}`
    },
    body: payload
  })
    .then((response) => {
      processTokens(response);
      return response.text();
    })
    .then((data) => {
      if (data) {
        return { uploadUrl: data };
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

const fileDownloadHelper = async (url, method, payload, downloadFileName ) => {
  return fetch(url, {
    method,
    headers: { Authorization: `Bearer ${sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME)}` }
  })
  .then( res => res.blob() )
  .then( blob => {
    var file = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = file;
    //link.setAttribute('download', downloadFileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    //window.location.assign(file);
  })
  .catch((error) => {
      console.error('Error:', error);
  });
};


const processTokens = (response) => {
  const newAccessToken = response.headers.get('Usas-New-Access-Token');
  const newRefreshToken = response.headers.get('Usas-New-Refresh-Token');
  const expirationDatetime = response.headers.get('Usas-Expiration-Datetime');
  if (newAccessToken !== undefined && newAccessToken !== null && newAccessToken !== ''
    && newRefreshToken !== undefined && newRefreshToken !== null && newRefreshToken !== ''
    && expirationDatetime !== undefined && expirationDatetime !== null && expirationDatetime !== '') {
    const newCookie = {
      token: newAccessToken,
      refreshToken: newRefreshToken,
      expirationDatetime: expirationDatetime,
      username: sessionStorage.getItem(Constants.USERNAME)
    }
    const newCookieValue = JSON.stringify(newCookie);
    document.cookie = `${encodeURIComponent(Constants.TOKEN_STORAGE_NAME)}=${encodeURIComponent(newCookieValue)}; path=/; domain=${import.meta.env.VITE_COOKIE_DOMAIN}`;
    sessionStorage.setItem(Constants.TOKEN_STORAGE_NAME, newAccessToken);
    sessionStorage.setItem(Constants.REFRESH_TOKEN_STORAGE_NAME, newRefreshToken);
    sessionStorage.setItem(Constants.EXPIRATION_DATETIME, expirationDatetime);
  }
};

const ApiHelper = (baseRoute, route, method, payload, isFile = false, downloadFileName = '') => {
  const url = `${baseRoute}${route}`;
  console.log(`${method}: ${url}`);

  if (isFile === true) {
    return filePostApiHelper(url, payload);
  } else if (downloadFileName) {
    return fileDownloadHelper(url, method, payload, downloadFileName);
  } else {
    return jsonApiHelper(url, method, payload);
  }
};

//The following APIs no longer exist
export const SwimsHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_SWIMSAPI_URL, route, method, payload, isFile, downloadFileName);
export const OmrHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_OMRAPI_URL, route, method, payload, isFile, downloadFileName);
export const SecurityHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_SECURITYAPI_URL, route, method, payload, isFile, downloadFileName);

export const CommonHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_COMMONAPI_URL, route, method, payload, isFile, downloadFileName);
export const ClubHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_CLUBAPI_URL, route, method, payload, isFile, downloadFileName);
export const MeetHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_MEETAPI_URL, route, method, payload, isFile, downloadFileName);
export const PersonHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_PERSONAPI_URL, route, method, payload, isFile, downloadFileName);
export const TimesHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_TIMESAPI_URL, route, method, payload, isFile, downloadFileName);
export const PreCompHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_PRECOMP_URL, route, method, payload, isFile, downloadFileName);
export const RecHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_RECAPI_URL, route, method, payload, isFile, downloadFileName);
export const LmsHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_LMSAPI_URL, route, method, payload, isFile, downloadFileName);
export const SafeSportHttpHelper = (route, method, payload, isFile, downloadFileName) => {
  const r = /(^https:\/\/\S+)(\/lms)$/gi;
  const apiUrl = import.meta.env.VITE_LMSAPI_URL.replace(r, '$1');
  return ApiHelper(apiUrl, route, method, payload, isFile, downloadFileName);
};
export const PaymentsHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_PAYMENTSAPI_URL, route, method, payload, isFile, downloadFileName);
export const PaymentsAdminHttpHelper = (route, method, payload, isFile, downloadFileName) => ApiHelper(import.meta.env.VITE_PAYMENTSADMINAPI_URL, route, method, payload, isFile, downloadFileName);

export const API_ERROR_MESSAGE = 'There was a problem with the data store.  Please try again later';
export const CORS_ERROR_MESSAGE = 'There was a security problem while attempting to connect to the database.';
export const NO_DATA_MESSAGE = 'The data could not be retrieved from the database.';
export const BASIC_INITIAL_STATE = {
  isArrayLoading: false,
  isArrayLoaded: false,
  isObjLoading: false,
  isObjLoaded: false,
  isSaving: false,
  isSaved: false,
  arrayData: [],
  objData: {},
  message: ''
};

const HttpHelper = { ApiHelper };
export default HttpHelper;

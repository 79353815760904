import { useState } from 'react';

import Button from '../../../common/components/buttons/PrimaryButton';
import Headings from '../../../common/components/headings/Headings';
import Input from '../../../common/components/inputs/Input';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import PurchaseItemGrid from './components/purchaseItemGrid/PurchaseItemGrid';

import { formatDate, formatDateTimeToTimeTwelveHourClock } from '../../../common/utils/DateFunctions';
import { FormatMoneyWithSymbol } from '../../../common/utils/FormatMoney';

import useLandingPage from './UseLandingPage';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

const LandingPage = () => {
  const [state, setState] = useState({
    receiptNumber: '',
    errorMessage: null
  });

  const { searchHelper, searchState, setSearchState, submitHelper, submitState, setSubmitState } = useLandingPage();

  const onModalCanceled = () => {
    submitState.objData.successMessage = null;
    submitState.objData.errorMessage = null;
    setSubmitState({
      ...submitState
    });
  }

  const onReceiptNumberChange = (value) => {
    setState({
      ...state,
      receiptNumber: value
    });
  }

  const onSearchClick = (e) => { 
    e?.preventDefault();
    setState({
      ...state,
      errorMessage: null
    });
    searchHelper(state.receiptNumber)
      .then((newSearchState) => {
        newSearchState.objData.amount = 0;
        newSearchState.objData.checked = 0;
        newSearchState.objData.items.forEach((value) => {
          value.refund = FormatMoneyWithSymbol(0);
        });
        setSearchState({
          ...newSearchState,
          isObjLoading: false,
          message: null
        });
      })
      .catch(() => {
        setState({
          ...state,
          errorMessage: 'The Receipt Number was not found.'
        });
        setSearchState({
          ...searchState,
          isObjLoading: false,
          isObjLoaded: false,
          message: null,
          objData: null
        });
      })
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    setState({
      ...state,
      errorMessage: null
    });
    submitHelper(searchState.objData.receiptNumber, searchState.objData.hash, searchState.objData.items)
      .then((newSubmitState) => {
        setSubmitState({
          ...newSubmitState,
          isObjLoading: false,
          message: null
        });
        if (newSubmitState.objData.success === true) {
          onSearchClick();
        }
      })
      .catch(() => {
        setState({
          ...state,
          errorMessage: 'An error occurred.'
        });
        setSubmitState({
          ...submitState,
          isObjLoading: false,
          isObjLoaded: false,
          message: null,
          objData: null
        })
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <p><Headings.H3 isBarOnBottom={false}>PAYMENTS ADMIN</Headings.H3></p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          Receipt Number:
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-3">
          <Input autoFocus={true} error={state.errorMessage !== null} message={state.errorMessage} onChange={(value) => onReceiptNumberChange(value)} value={state.receiptNumber} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Button onClick={(e) => onSearchClick(e)}>Search</Button>
        </div>
      </div>
      {searchState.isObjLoaded === true && (
        <>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-2">
              <b>Member ID:</b>
            </div>
            <div className="col-xs-10">
              {searchState.objData.memberId}
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-2">
              <b>Name:</b>
            </div>
            <div className="col-xs-10">
              {searchState.objData.firstName} {searchState.objData.lastName}
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-2">
              <b>Email Address:</b>
            </div>
            <div className="col-xs-10">
              {searchState.objData.emailAddress}
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-2">
              <b>Payment Date:</b>
            </div>
            <div className="col-xs-10">
              {formatDate(searchState.objData.paymentDate)} {formatDateTimeToTimeTwelveHourClock(searchState.objData.paymentDate)} UTC
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PurchaseItemGrid purchaseItems={searchState.objData.items} total={searchState.objData.amount} searchState={searchState} setSearchState={setSearchState} onSubmitClick={onSubmitClick} />
            </div>
          </div>
        </>
      )}
      {(searchState.isObjLoading === true || submitState.isObjLoading === true) && (
        <LoadingModal></LoadingModal>
      )}
      {submitState.objData?.successMessage !== undefined && submitState.objData?.successMessage !== null && (
        <PopUpModal displayPopUp={true} maxWidth={720} onModalCanceled={() => onModalCanceled()} title="Success" widthPct={90}>
          <>
            <div className="row">
              <div className="col-xs-12">
                {submitState.objData.successMessage}
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className={["col-xs-12", "text-center"].join(' ')}>
                <br />
                <PrimaryButton onClick={() => onModalCanceled()}>OK</PrimaryButton>
              </div>
            </div>
          </>
        </PopUpModal>
      )}
      {submitState.objData?.errorMessage !== undefined && submitState.objData?.errorMessage !== null && (
        <PopUpModal displayPopUp={true} maxWidth={720} onModalCanceled={() => onModalCanceled()} title="Error" widthPct={90}>
          <>
            <div className="row">
              <div className="col-xs-12">
                {submitState.objData.errorMessage}
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className={["col-xs-12", "text-center"].join(' ')}>
                <br />
                <PrimaryButton onClick={() => onModalCanceled()}>OK</PrimaryButton>
              </div>
            </div>
          </>
        </PopUpModal>
      )}
    </>
  )
};

export default LandingPage;
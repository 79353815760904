import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

  const getTransactionForPayment = (shoppingCartId, foundationDonation, coverTechnologyFee, callAgain, state, setState) => {
    //TODO depending on how callAgain is determined, an infinite loop could occur here
    if ((state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) || callAgain === true) {
      let newState = {
        ...state,
        isObjLoading: true,
        isObjLoaded: false,
        message: 'Loading...'
      };
      setState(newState);
      
      const url = `/ShoppingCart/${encodeURIComponent(shoppingCartId)}?FoundationDonation=${foundationDonation}&CoverTechnologyFee=${coverTechnologyFee}`;
      CommonHttpHelper(url, 'GET')
        .then((objData) => {
          if (!objData) {
            throw new Error(NO_DATA_MESSAGE);
          } else {
            newState = {
              ...newState,
              isObjLoading: false,
              isObjLoaded: true,
              objData,
              message: ''
            };
            setState(newState);
          }
        })
        .catch((e) => {
          const message = e && e.message ? e.message : NO_DATA_MESSAGE;
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: false,
            message: message
          });
        });
    }
  };

  export default getTransactionForPayment;
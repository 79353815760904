/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import useLogin from './UseLogin';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { faCheckCircle, faCircle, faEye, faEyeSlash, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from '../../../../../common/utils/Constants';
import Headings from '../../../../../common/components/headings/Headings';
import Input from '../../../../../common/components/inputs/Input';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

const STEP_LOGIN_STEP_1 = 'STEP_LOGIN_STEP_1';
const STEP_LOGIN_STEP_2 = 'STEP_LOGIN_STEP_2';
const STEP_LOGIN_STEP_3 = 'STEP_LOGIN_STEP_3';

const Login = ({ onModalCanceled, setShowSessionEnding, calculateShowSessionEnding }) => {
  const {
    loginState, oneTimePasswordCheckState, passwordResetState,
    sharedFormState, loginFormState,
    handleLoginPasswordChange, toggleLoginShowPassword, onLoginClicked, handleLoginOneTimePasswordChange, onLoginSubmitClicked, handleLoginCurrentPasswordChange, toggleLoginShowCurrentPassword, handleLoginNewPasswordChange, toggleLoginShowNewPassword, onLoginChangePasswordClicked,
    onCancelClicked
  } = useLogin(onModalCanceled, setShowSessionEnding, calculateShowSessionEnding);

  return (
    <Fragment>
      {sharedFormState.step === STEP_LOGIN_STEP_1 && (
        <Fragment>
          <form onSubmit={(e) => onLoginClicked(e)}>
            <div className="row">
              {/* <div className="col-xs-12 col-md-4"></div> */}
              <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
                {loginFormState.error !== '' && (
                  <p style={{ color: 'red' }}>{loginFormState.error}</p>
                )}
              </div>
            </div>
            <div className="row usas-extra-bottom-margin">
              <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
                <div className="usas-extra-top-margin">
                  Username:<br />
                  {loginFormState.username}
                  {/* <Input error={loginFormState.usernameError} message="Username is required" onChange={(value) => handleLoginUsernameChange(value)}></Input> */}
                </div>
              </div>
            </div>
            <div className="row usas-extra-bottom-margin">
              <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
                <div className="usas-extra-top-margin">
                  Password:
                  <FontAwesomeIcon icon={loginFormState.showPassword === true ? faEye : faEyeSlash} onClick={() => toggleLoginShowPassword()} style={{ cursor: 'pointer', marginRight: loginFormState.showPassword === true ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
                  <Input autoFocus={true} error={loginFormState.passwordError} message="Password is required" onChange={(value) => handleLoginPasswordChange(value)} type={loginFormState.showPassword ? "text" : "password"}></Input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
                <div style={{ marginBottom: -5, marginTop: 15 }}>
                  <PrimaryButton type="submit">Login</PrimaryButton>&nbsp;&nbsp;&nbsp;&nbsp;<SecondaryButton onClick={() => onModalCanceled()}>Cancel</SecondaryButton>
                </div>
              </div>
            </div>
          </form>
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={loginState.isArrayLoading || loginState.isObjLoading} />
        </Fragment>
      )}
      {sharedFormState.step === STEP_LOGIN_STEP_2 && (
        <Fragment>
          <div className="row">
            {/* <div className="col-xs-12 col-md-4"></div> */}
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              {loginFormState.error === '' && (
                <p>Look for the one-time code that was sent to {loginFormState.device} and enter it here.</p>
              )}
              {loginFormState.error !== '' && (
                <p style={{ color: 'red' }}>{loginFormState.error}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div className="usas-extra-top-margin">
                One-Time Code:
                <Input error={loginFormState.oneTimePasswordError} message="One-Time Code is required" onChange={(value) => handleLoginOneTimePasswordChange(value)}></Input>
              </div>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div style={{ marginBottom: -5, marginTop: 15 }}>
                <PrimaryButton onClick={(e) => onLoginSubmitClicked(e)}>Submit</PrimaryButton>&nbsp;&nbsp;&nbsp;&nbsp;<SecondaryButton onClick={(e) => onCancelClicked()}>Cancel</SecondaryButton>
              </div>
            </div>
          </div>
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={oneTimePasswordCheckState.isArrayLoading || oneTimePasswordCheckState.isObjLoading} />
        </Fragment>
      )}
      {sharedFormState.step === STEP_LOGIN_STEP_3 && (
        <Fragment>
          <div className="row">
            {/* <div className="col-xs-12 col-md-4"></div> */}
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              {loginFormState.error === '' && (
                <p>Your password has expired and must be changed.</p>
              )}
              {loginFormState.error !== '' && (
                <p style={{ color: 'red' }}>{loginFormState.error}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div className="usas-extra-top-margin">
                Current Password:
                <FontAwesomeIcon icon={loginFormState.showCurrentPassword === true ? faEye : faEyeSlash} onClick={() => toggleLoginShowCurrentPassword()} style={{ cursor: 'pointer', marginRight: loginFormState.showCurrentPassword === true ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
                <Input error={loginFormState.currentPasswordError} message="Current Password is required" onChange={(value) => handleLoginCurrentPasswordChange(value)} type={loginFormState.showCurrentPassword ? "text" : "password"}></Input>
              </div>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div className="usas-extra-top-margin">
                Password Requirements
                <p style={{ fontSize: 14 }}>
                  <FontAwesomeIcon color={loginFormState.newPassword10Characters === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPassword10Characters === true ? faCheckCircle : faCircle}></FontAwesomeIcon> No less than 10 characters<br />
                  <FontAwesomeIcon color={loginFormState.newPassword1Number === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPassword1Number === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 number<br />
                  <FontAwesomeIcon color={loginFormState.newPassword1SpecialCharacter === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPassword1SpecialCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 special character<br />
                  <FontAwesomeIcon color={loginFormState.newPassword1LowercaseCharacter === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPassword1LowercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 lowercase character<br />
                  <FontAwesomeIcon color={loginFormState.newPassword1UppercaseCharacter === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPassword1UppercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 uppercase character<br />
                  <FontAwesomeIcon color={loginFormState.newPasswordNotEqualToUsername === true ? 'green' : '#c1c6c8'} icon={loginFormState.newPasswordNotEqualToUsername === true ? faCheckCircle : faCircle}></FontAwesomeIcon> Not equal to username
                </p>
              </div>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div className="usas-extra-top-margin">
                New Password:
                <FontAwesomeIcon icon={loginFormState.showNewPassword === true ? faEye : faEyeSlash} onClick={() => toggleLoginShowNewPassword()} style={{ cursor: 'pointer', marginRight: loginFormState.showNewPassword === true ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
                <Input error={loginFormState.newPasswordError} message="New Password is required" onChange={(value) => handleLoginNewPasswordChange(value)} type={loginFormState.showNewPassword ? "text" : "password"}></Input>
              </div>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className={[...global.CenterText, "col-xs-12"].join(' ')}>
              <div style={{ marginBottom: -5, marginTop: 15 }}>
                <PrimaryButton
                  isDisabled={loginFormState.submitButtonIsDisabled}
                  onClick={(e) => onLoginChangePasswordClicked(e)}>Save</PrimaryButton>&nbsp;&nbsp;&nbsp;&nbsp;<SecondaryButton onClick={(e) => onCancelClicked()}>Cancel</SecondaryButton>
              </div>
            </div>
          </div>
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={passwordResetState.isArrayLoading || passwordResetState.isObjLoading} />
        </Fragment>
      )}
    </Fragment>
  )
}

export default Login;
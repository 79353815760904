import { useCookies } from 'react-cookie';
import Constants from '../../utils/Constants';

const useToken = () => {
  const [cookies, setCookie] = useCookies([Constants.TOKEN_STORAGE_NAME]);

  const resetStoredToken = () => {
    // commented code is because we're moving session timeout from the client to the identity provider
    //setCookie(Constants.TOKEN_STORAGE_NAME, {token: undefined, refreshToken: undefined, refreshDate: undefined}, {...Constants.COOKIE_OPTIONS, expires: new Date()});
    setCookie(Constants.TOKEN_STORAGE_NAME, {token: undefined, refreshToken: undefined, expirationDatetime: undefined, username: undefined}, {...Constants.COOKIE_OPTIONS});
    sessionStorage.setItem(Constants.TOKEN_STORAGE_NAME, '');
    sessionStorage.setItem(Constants.REFRESH_TOKEN_STORAGE_NAME, '');
    sessionStorage.setItem(Constants.EXPIRATION_DATETIME, '');
    sessionStorage.setItem(Constants.USERNAME, '');
  };

  //const setStoredToken = (token, refreshToken, refreshSecurityInfo = false) => {
  const setStoredToken = (token, refreshToken, expirationDatetime, username) => {
    // commented code is because we're moving session timeout from the client to the identity provider
    //const expires = new Date();
    //expires.setMinutes(expires.getMinutes() + (8 * 60));
    //const newTokenObj = cookies[Constants.TOKEN_STORAGE_NAME] || {token, refreshToken, refreshDate: undefined};
    const newTokenObj = cookies[Constants.TOKEN_STORAGE_NAME] || {token, refreshToken, expirationDatetime, username};
    if (newTokenObj?.token !== token || newTokenObj?.refreshToken !== refreshToken || newTokenObj?.expirationDatetime !== expirationDatetime || newTokenObj?.username !== username) {
      newTokenObj.token = token;
      newTokenObj.refreshToken = refreshToken;
      newTokenObj.expirationDatetime = expirationDatetime;
      newTokenObj.username = username;
      //newTokenObj.refreshDate = newTokenObj?.refreshDate || undefined;
    }

    // if (!newTokenObj?.refreshDate || refreshSecurityInfo === true) {
    //   const refreshDate = new Date();
    //   refreshDate.setMinutes(refreshDate.getMinutes() + (8 * 60));
    //   newTokenObj.refreshDate = refreshDate;
    // }

    //setCookie(Constants.TOKEN_STORAGE_NAME, {...newTokenObj}, {...Constants.COOKIE_OPTIONS, expires});
    setCookie(Constants.TOKEN_STORAGE_NAME, {...newTokenObj}, {...Constants.COOKIE_OPTIONS});
    sessionStorage.setItem(Constants.TOKEN_STORAGE_NAME, token);
    sessionStorage.setItem(Constants.REFRESH_TOKEN_STORAGE_NAME, refreshToken);
    sessionStorage.setItem(Constants.EXPIRATION_DATETIME, expirationDatetime);
    sessionStorage.setItem(Constants.USERNAME, username);
  };

  const getStoredTokenObj = () => {
    // commented code is because we're moving session timeout from the client to the identity provider
    const tokenObj = cookies[Constants.TOKEN_STORAGE_NAME];
    //const refreshSecurityInfo = !tokenObj?.refreshDate || tokenObj?.refreshDate < new Date() ? true : false;
    //if (tokenObj?.token || tokenObj?.refreshToken) setStoredToken(tokenObj.token, tokenObj.refreshToken, refreshSecurityInfo);
    if (tokenObj?.token || tokenObj?.refreshToken || tokenObj?.expirationDatetime || tokenObj?.username) setStoredToken(tokenObj.token, tokenObj.refreshToken, tokenObj.expirationDatetime, tokenObj.username);
    //return {token: tokenObj?.token, refreshToken: tokenObj?.refreshToken, refreshSecurityInfo};
    return {token: tokenObj?.token, refreshToken: tokenObj?.refreshToken, expirationDatetime: tokenObj?.expirationDatetime, username: tokenObj?.username};
  };

  return {
    resetStoredToken,
    setStoredToken,
    getStoredTokenObj
  };
};

export default useToken;
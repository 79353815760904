import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

const getUrl = (url, orgUnitId, itemSubsystemId, itemContract, referenceId, personId) => {
  let nextCharacter = '?';
  if (orgUnitId) {
    url += `${nextCharacter}OrgUnitId=${encodeURIComponent(orgUnitId)}`;
    nextCharacter = '&';
  }
  if (itemSubsystemId) {
    url += `${nextCharacter}ItemSubsystemId=${encodeURIComponent(itemSubsystemId)}`;
    nextCharacter = '&';
  }
  if (itemContract) {
    url += `${nextCharacter}ItemContract=${encodeURIComponent(itemContract)}`;
    nextCharacter = '&';
  }
  if (referenceId) {
    url += `${nextCharacter}ReferenceId=${encodeURIComponent(referenceId)}`;
    nextCharacter = '&';
  }
  if (personId) {
    url += `${nextCharacter}PersonId=${encodeURIComponent(personId)}`;
    nextCharacter = '&';
  }
  return url;
}

const getCart = (orgUnitId, itemSubsystemId, itemContract, referenceId) => {
  const url = getUrl(`ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/Payments/Cart`, orgUnitId, itemSubsystemId, itemContract, referenceId);
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
  }
  return result;
}

const deleteItem = (orgUnitId, itemSubsystemId, itemContract, referenceId, id) => {
  const url = getUrl(`ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/Payments/Cart/${id}/Delete`, orgUnitId, itemSubsystemId, itemContract, referenceId);
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
  }
  return result;
}

const checkout = (orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn) => {
  const url = getUrl(`ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/Payments/Checkout/${foundationDonation}/${coverTechnologyFee}/${swimmingWorldOptIn}`, orgUnitId, itemSubsystemId, itemContract, referenceId);
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
    result.send(checkoutIds);
  }
  return result;
}

const downloadReceipt = (paymentId, showDetail) => {
  const url = `ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/Payments/${paymentId}/DownloadReceipt/${showDetail}`;
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
  }
  return result;
}

const downloadReceiptAdmin = (receiptNumber, showDetail) => {
  const url = `ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/PaymentsAdmin/${receiptNumber}/DownloadReceiptAdmin/${showDetail}`;
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
  }
  return result;
}

const getCartCount = async (orgUnitId, itemSubsystemId, itemContract, referenceId, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = getUrl('/Payments/Cart/Count', orgUnitId, itemSubsystemId, itemContract, referenceId);
  return api?.executeObject ? api.executeObject(url, 'GET') : null;
}


const checkoutByCheck = (orgUnitId, itemSubsystemId, itemContract, referenceId, checkoutIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, personId) => {
  const url = getUrl(`ws${import.meta.env.VITE_PAYMENTSAPI_URL.substring(4)}/Payments/Checkout/Check/${foundationDonation}/${coverTechnologyFee}/${swimmingWorldOptIn}`, orgUnitId, itemSubsystemId, itemContract, referenceId, personId);
  let result = new WebSocket(url);
  result.onopen = () => {
    result.send(sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME));
    result.send(sessionStorage.getItem(Constants.REFRESH_TOKEN_STORAGE_NAME));
    result.send(checkoutIds);
  }
  return result;
}

const PaymentsData = { 
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getCart,
  deleteItem,
  checkout,
  checkoutByCheck,
  downloadReceipt,
  downloadReceiptAdmin,
  getCartCount
};

export default PaymentsData;
import React, { Fragment } from 'react';

import usePurchaseItemGrid from './UsePurchaseItemGrid';

import LargeGrid from './LargeGrid';
//import SmallGrid from './SmallGrid';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import style from '../../LandingPage.module.css'

const PurchaseItemGrid = ({ purchaseItems, total, searchState, setSearchState, onSubmitClick }) => {
  const {
      //purchaseItems,
      //subTotal,
      //totalAmount,
      onSelectedChanged,
      onRefundChanged,
      onRefundBlurred
      //onDeleteItem,
      //onCheckoutClicked
    } = usePurchaseItemGrid(searchState, setSearchState);

  return (
    <Fragment>

      {purchaseItems?.length > 0 && (
      <Fragment>
        <LargeGrid purchaseItems={purchaseItems} total={total} onSelectedChanged={onSelectedChanged} onRefundChanged={onRefundChanged} onRefundBlurred={onRefundBlurred} />
        {/* <SmallGrid purchaseItems={purchaseItems} total={total} onSelectedChanged={onSelectedChanged} onRefundChanged={onRefundChanged} onRefundBlurred={onRefundBlurred} /> */}
      </Fragment>
      )}

      <div className="row">
        <div className="col-xs-12 text-right">
          <PrimaryButton type="button" className={style.Buttons} isDisabled={searchState.objData.checked === 0} onClick={(e) => onSubmitClick(e)}>Cancel / Refund</PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default PurchaseItemGrid;
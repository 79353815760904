import React, { createContext, useState } from 'react';

import ShoppingCartData from './ShoppingCartData';

export const ShoppingCartStateContext = createContext();

const ShoppingCartContextProvider = ({ children }) => {
  const paymentsHook = useState(ShoppingCartData.INITIAL_STATE);

  return (
    <ShoppingCartStateContext.Provider value={paymentsHook}>
      {children}
    </ShoppingCartStateContext.Provider>
  );
};

export default ShoppingCartContextProvider;
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getShoppingCarts from './GetShoppingCarts';
import getTransactionForPayment from './GetTransactionForPayment';
import getTransactionResolution from './GetTransactionResolution';
import getOpenOmrCartItems from './GetOpenOmrCartItems';
import getProcessZeroDollarShoppingCart from './GetProcessZeroDollarShoppingCart';

const ShoppingCartData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE, hasUnpaidTransactions: false, foundationDonation: 0, coverTechnologyFee: false },
  CURRENT_TXN_INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getShoppingCarts,
  getTransactionForPayment,
  getTransactionResolution,
  getOpenOmrCartItems,
  getProcessZeroDollarShoppingCart
};

export default ShoppingCartData;

import { useEffect, useState } from 'react';

import Constants from '../../../../utils/Constants';

const useSessionEnding = (setShowSessionEnding, calculateShowSessionEnding) => {
  const [ state, setState ] = useState({
    expiringIn: '00:00:00'
  });

  const onModalCanceled = () => {
    setShowSessionEnding(false);
  }

  const calculateExpiringIn = () => {
    let result = '00:00:00';
    if (Number.isInteger(Date.parse(sessionStorage.getItem(Constants.EXPIRATION_DATETIME)))) {
      const expirationDatetime = new Date(Date.parse(sessionStorage.getItem(Constants.EXPIRATION_DATETIME)));
      const now = new Date();
      let ms = expirationDatetime.getTime() - now.getTime();
      const hours = Math.floor(ms / (60 * 60 * 1000));
      ms -= hours * (60 * 60 * 1000);
      const minutes = Math.floor(ms / (60 * 1000));
      ms -= minutes * (60 * 1000);
      const seconds = Math.floor(ms / (1000));
      result = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
    setState({
      ...state,
      expiringIn: result
    });
  }

  useEffect(() => {
    const interval = setInterval(() => calculateExpiringIn(), 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    onModalCanceled,
    state
  }
}

export default useSessionEnding;
import { useContext } from 'react';

import { useLocation, useNavigate, useSearchParams } from '../../wrappers/ReactRouterDom';
import useToken from './UseToken';

import { SecurityStateContext, CurrentNavStateContext, ContextSecurityStateContext } from './SecurityContextProvider';
import SecurityData from './SecurityData';

const TAXONOMIES = ['TopMenu', 'Footer', 'MainMenu'];
const SCOPE = 'Project';

const useSecurityData = () => {
  const [securityState, setSecurityState] = useContext(SecurityStateContext);
  const [currentNavItem, setCurrentNavItem] = useContext(CurrentNavStateContext);
  const [contextSecurityState, setContextSecurityState] = useContext(ContextSecurityStateContext);
  const [searchParams,] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { resetStoredToken, setStoredToken, getStoredTokenObj } = useToken();

  const getUserInfo = () => {
    const userInfo = securityState.userInfo || {};
    userInfo.isLoggedIn = userInfo?.firstName !== 'Anonymous';
    userInfo.isUnregistered = userInfo?.firstName === 'Unregistered';
    return userInfo;
  };

  const login = () => {
    resetStoredToken();
    SecurityData.login();
  };

  const logout = (retUrl) => {
    retUrl = retUrl || window.location.origin;
    resetStoredToken();
    SecurityData.logout(retUrl);
  };

  const logoutGoToCreateALogin = (childMemberId) => {
    resetStoredToken();
    SecurityData.logoutWithMemberIdParam(childMemberId);
  };

  const logoutGoToAccountCreation = (paramsObj) => {
    resetStoredToken();
    SecurityData.logoutWithParams(paramsObj);
  };

  const closeSecondaryNav = () => {
    setCurrentNavItem({});
  };

  const onPrimaryNavItemChanged = (newNavItem) => {
    if (!newNavItem && !currentNavItem) return;
    if (newNavItem?.displayName === currentNavItem?.displayName) return;

    setCurrentNavItem({ ...newNavItem });
  };

  const resetContextSecurityState = () => {
    setContextSecurityState({ ...SecurityData.CONTEXT_SECURITY_INITIAL_STATE });
  };

  const getContextSecurity = (contextId, taxonomies, scope) => {
    return SecurityData.getRoutesForContext(contextId, taxonomies, scope, contextSecurityState, setContextSecurityState);
  };

  const verifyUser = () => {
    const storedTokenObj = getStoredTokenObj();
    setStoredToken(storedTokenObj.token, storedTokenObj.refreshToken, storedTokenObj.expirationDatetime, storedTokenObj.username);
    if (storedTokenObj.refreshSecurityInfo || securityState.isObjLoaded === false) {
      SecurityData.GetSecurityInfoForToken(TAXONOMIES, SCOPE, storedTokenObj.token, false, securityState, setSecurityState);
    }
  };

  const bustSecurityCache = () => {
    const storedTokenObj = getStoredTokenObj();
    setStoredToken(storedTokenObj.token, storedTokenObj.refreshToken, storedTokenObj.expirationDatetime, storedTokenObj.username);
    SecurityData.GetSecurityInfoForToken(TAXONOMIES, SCOPE, storedTokenObj.token, true, securityState, setSecurityState);
  };

  const completeLogin = (qsToken, qsRefreshToken, qsExpirationDatetime, qsUsername) => {
    setStoredToken(qsToken, qsRefreshToken, qsExpirationDatetime, qsUsername);
    const memberId = searchParams.get('memberId');
    if (memberId) {
      navigate(location.pathname + `?memberId=${memberId}`, { replace: true });
    }
    else {
      navigate(location.pathname, { replace: true });
    }
  };

  const setContextNavVisibility = (showNav) => {
    setContextSecurityState({
      ...contextSecurityState,
      showNav
    });
  }

  return {
    securityState,
    userInfo: getUserInfo(),
    contextSecurityState,
    setContextNavVisibility,
    currentNavItem,
    closeSecondaryNav,
    login,
    logout,
    logoutGoToCreateALogin,
    logoutGoToAccountCreation,
    onPrimaryNavItemChanged,
    resetContextSecurityState,
    getContextSecurity,
    verifyUser,
    completeLogin,
    bustSecurityCache,
    resetStoredToken
  };
};

export default useSecurityData;
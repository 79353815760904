import React from 'react';
import Unauthorized from './Unauthorized';

const SecuredRouteElement = ({navRoute, editElement, readOnlyElement, unAuthorizedElement = <Unauthorized />}) => {
  if (navRoute?.permission === 'read' && readOnlyElement) {
    return readOnlyElement;
  }

  if (navRoute?.permission === 'edit' && editElement) {
    return editElement;
  }

  return unAuthorizedElement;
};

export default SecuredRouteElement;
import React from 'react';
import {VisuallyHidden} from '@reach/visually-hidden';

import useShield from './UseShield';

import logo from '../../images/ShieldWebsiteMain.svg';

const Shield = ({className}) => {
  const { onClick } = useShield();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="" onClick={onClick}>
      <VisuallyHidden>Logo - Link to Home</VisuallyHidden>
      <img className={className} src={logo} alt="Logo" />
    </a>
  );
};

export default Shield;
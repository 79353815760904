import { useState } from 'react';

import PaymentsAdminData from './PaymentsAdminData';

const usePaymentsAdminData = () => {
  const [searchState, setSearchState] = useState({});
  const [submitState, setSubmitState] = useState({});

  const search = (receiptNumber) => {
    return PaymentsAdminData.search(receiptNumber, searchState, setSearchState);
  }

  const submit = (receiptNumber, hash, purchaseItems) => {
    return PaymentsAdminData.submit(receiptNumber, hash, purchaseItems, submitState, setSubmitState);
  }

  return {
    search,
    searchState,
    setSearchState,
    submit,
    submitState,
    setSubmitState
  };
};

export default usePaymentsAdminData;
import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const search = async (receiptNumber, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTSADMIN, state, setState);
  const url = `/Payments/${encodeURIComponent(receiptNumber)}`;
  return api?.executeObject ? api.executeObject(url, 'GET') : null;
}

const submit = async(receiptNumber, hash, purchaseItems, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTSADMIN, state, setState);
  const url = `/Payments/${encodeURIComponent(receiptNumber)}`;
  let payload = {
    hash: hash,
    details: []
  }
  purchaseItems.forEach((value) => {
    if (value.checked === true) {
      payload.details.push({
        detailId: value.detailId,
        parentDetailId: value.parentDetailId,
        refund: new Number(value.refund.replace('$', '')) * 100
      });
    }
  });
  return api?.executeObject? api.executeObject(url, 'POST', payload) : null;
}

const PaymentsAdminData = { 
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  search,
  submit
};

export default PaymentsAdminData;
import React from 'react';
import { Link } from '../wrappers/ReactRouterDom';

import Usas404 from '../images/Usas404.jpg';
import global from './GlobalStyle.module.css';

const PageNotFound = () => {

  return (
    <div className={global.CenterText}>
      <img src={Usas404} alt="Page not found" />
      <p className="CenterText">
        <Link to="/">Return to Home </Link>
      </p>
    </div>
  );
};

export default PageNotFound;
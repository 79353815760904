import { useEffect } from 'react';

import useGlobalRoutesData from '../../state/security/UseGlobalRoutesData';
import useCrossUINavigation from '../../state/crossUINavigation/UseCrossUINavigation';

const useToLandingPage = () => {
  const { globalRoutesState, getGlobalRoutesData } = useGlobalRoutesData();
  const { navigateToRouteName } = useCrossUINavigation();

  useEffect(() => {
    if (globalRoutesState.isArrayLoaded === true) {
      navigateToRouteName('HUB_LANDING_PAGES');
    } else if (globalRoutesState.isArrayLoading !== true) {
      getGlobalRoutesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalRoutesState]);

  return {};
};

export default useToLandingPage;
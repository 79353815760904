import React, { Fragment } from 'react';

import { FormatMoneyWithSymbol } from '../../../../../common/utils/FormatMoney';

import CheckBox from '../../../../../common/components/checkboxes/Checkbox';
import CurrencyInput from '../../../../../common/components/inputs/CurrencyInput';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from '../../LandingPage.module.css';

const LargeGrid = ({ purchaseItems, total, onSelectedChanged, onRefundChanged, onRefundBlurred, hideHeader, level = 1 }) => {
  const getCheckboxDisabled = (purchaseItem) => {
    let result = purchaseItem.checkboxDisabled;
    if (result !== true && result !== false) {
      result = false;
    }
    if (result === false) {
      result = purchaseItem.itemPrice > 0
        && purchaseItem.itemPrice + purchaseItem.adjustments <= 0;
    }
    if (result === false) {
      result = purchaseItem.itemPrice === 0
        && purchaseItem.cancellationPayments > 0;
    }
    if (result === false
      && purchaseItem.itemPrice + purchaseItem.adjustments <= 0
      && (purchaseItem.itemDescription === 'Foundation Donation' || purchaseItem.itemDescription === 'Technology Fee')) {
        result = true;
    }
    return result;
  }

  const getInputReadOnly = (purchaseItem) => {
    // let result = purchaseItem.checked !== true
    //   || purchaseItem.inputDisabled === true;
    // if (result === false) {
    //   result = purchaseItem.itemPrice + purchaseItem.adjustments <= 0;
    // }
    // return result;
    return true;
  }

  const getSpacing = (itemLevel) => {
    let result = '';
    for (let i = 2; i <= itemLevel; i++) {
      result += '&nbsp;&nbsp;&nbsp;&nbsp;';
    }
    return <span dangerouslySetInnerHTML={{__html: result}}></span>
  }

  return (
    <Fragment>
      <table className={[global.UsasTable, style.Table, 'visible-md visible-lg'].join(' ')} style={{ marginTop: level === 1 ? 5 : 0 }}>
        {hideHeader !== true && (
          <thead>
            <tr>
              <th className={[style.ItemColumn, 'text-left'].join(' ')} style={{ paddingLeft: 10 }}>Item</th>
              <th className={style.ExpandColumn} style={{ textAlign: 'center' }}>SELECT</th>
              <th className={[style.AmountColumn, 'text-right'].join(' ')} style={{ paddingRight: 0 }}>ORIGINAL</th>
              <th className={[style.AmountColumn, 'text-right'].join(' ')} style={{ paddingRight: 0 }}>ADJUSTMENTS</th>
              <th className={[style.AmountColumn, 'text-right'].join(' ')} style={{ paddingRight: 0 }}>BALANCE</th>
              <th className={[style.AmountColumn, 'text-right'].join(' ')} style={{ paddingRight: 10 }}>REFUND</th>
            </tr>
          </thead>
        )}
        <tbody>
          {purchaseItems.map((purchaseItem, i) => (
            <Fragment key={i}>
              <tr>
                <td className={[style.ItemColumn, 'text-left'].join(' ')} style={{ fontWeight: purchaseItem.level === 1 ? 'bold' : 'normal', paddingLeft: purchaseItem.level * 10 }}>
                  {getSpacing(purchaseItem.level)}
                  {purchaseItem.itemDescription}
                </td>
                <td className={style.ExpandColumn} style={{ textAlign: 'center' }}>
                  <CheckBox checked={purchaseItem.checked} disabled={getCheckboxDisabled(purchaseItem)} onChange={(checked) => onSelectedChanged(purchaseItems, purchaseItem, checked)} />
                </td>
                <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: purchaseItem.level === 1 ? 'bold' : 'normal', paddingRight: 0 }}>
                  {FormatMoneyWithSymbol(purchaseItem.itemPrice / 100)}
                </td>
                <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: purchaseItem.level === 1 ? 'bold' : 'normal', paddingRight: 0 }}>
                  {FormatMoneyWithSymbol(purchaseItem.adjustments / 100)}
                </td>
                <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: purchaseItem.level === 1 ? 'bold' : 'normal', paddingRight: 0 }}>
                  {FormatMoneyWithSymbol((purchaseItem.itemPrice / 100) + (purchaseItem.adjustments / 100))}
                </td>
                <td className={style.AmountColumn} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <input className={[global.BaseInputBox, style.Refund, purchaseItem.level === 1 ? style.RefundBold : ''].join(' ')} onBlur={(e) => onRefundBlurred(purchaseItems, purchaseItem, e)} onChange={(e) => onRefundChanged(purchaseItems, purchaseItem, e)} readOnly={getInputReadOnly(purchaseItem)} style={{ backgroundColor: getInputReadOnly(purchaseItem) === true ? '#F3F3F3' : '#FFFFFF' }} value={purchaseItem.refund} />
                </td>
              </tr>
            </Fragment>
          ))}
          {total >= 0 && (
            <tr style={{ backgroundColor: 'transparent', border: 0 }}>
              <td colSpan={5} style={{ paddingRight: 0, textAlign: 'right' }}>Total:</td>
              <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: 'bold', paddingRight: 15 }}>{FormatMoneyWithSymbol(total / 100)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default LargeGrid;
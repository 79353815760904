import { FormatMoneyWithSymbol } from '../../../../../common/utils/FormatMoney';

const usePurchaseItemGrid = (searchState, setSearchState) => {
  const getAmount = (purchaseItems) => {
    let result = 0;
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].checked === true
        && purchaseItems[i].parentDetailId === null) {
        result += new Number(purchaseItems[i].refund.replace('$', '')) * 100;
      }
    }
    return result;
  }

  const getCheckedCount = (purchaseItems) => {
    let result = 0;
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].checked === true) {
        result++;
      }
    }
    return result;
  }

  const getChildCount = (purchaseItems, purchaseItem) => {
    let result = 0;
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].parentDetailId === purchaseItem.detailId) {
        result++;
      }
    }
    return result;
  }

  const getChildCheckedCount = (purchaseItems, purchaseItem) => {
    let result = 0;
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].parentDetailId === purchaseItem.detailId
        && purchaseItems[i].checked === true) {
        result++;
      }
    }
    return result;
  }

  const getParentCount = (purchaseItems, purchaseItem) => {
    let result = 0;
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.parentDetailId) {
        result++;
      }
    }
    return result;
  }
  
  const onSelectedChanged = (purchaseItems, purchaseItem, checked) => {
    let value = 0;
    if (checked === true) {
      value = (purchaseItem.itemPrice + purchaseItem.adjustments) / 100;
    }
    onSelectedChanged1(purchaseItems, purchaseItem, checked);
    onRefundBlurred1(purchaseItems, purchaseItem, value);
    setSearchState({
      ...searchState,
      objData: {
        ...searchState.objData,
        amount: getAmount(purchaseItems),
        checked: getCheckedCount(purchaseItems),
        items: purchaseItems
      }
    });
  };

  const onSelectedChanged1 = (purchaseItems, purchaseItem, checked) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.detailId) {
        purchaseItems[i].checked = checked;
        purchaseItems[i].inputDisabled = getChildCount(purchaseItems, purchaseItems[i]) > 0;
        onSelectedChanged2(purchaseItems, purchaseItems[i], checked);
        onSelectedChanged3(purchaseItems, purchaseItems[i], checked);
        break;
      }
    }
  }

  const onSelectedChanged2 = (purchaseItems, purchaseItem, checked) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.parentDetailId) {
        //if (purchaseItems[i].itemPrice + purchaseItems[i].adjustments >= 0) {
        if (checked === true) {
          purchaseItems[i].refund = FormatMoneyWithSymbol((purchaseItems[i].itemPrice + purchaseItems[i].adjustments) / 100);
          purchaseItems[i].checked = true;
          purchaseItems[i].checkboxDisabled = true;
        }
        else if (checked === false
          && getChildCheckedCount(purchaseItems, purchaseItems[i]) === 0) {
          purchaseItems[i].refund = FormatMoneyWithSymbol(0);
          purchaseItems[i].checked = false;
          purchaseItems[i].checkboxDisabled = false;
        }
        //}
        purchaseItems[i].inputDisabled = getChildCount(purchaseItems, purchaseItems[i]) > 0;
        onSelectedChanged2(purchaseItems, purchaseItems[i], checked);
      }
    }
  }

  const onSelectedChanged3 = (purchaseItems, purchaseItem, checked) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].parentDetailId === purchaseItem.detailId) {
        //if (purchaseItems[i].itemPrice + purchaseItems[i].adjustments >= 0) {
          if (checked === true) {
            purchaseItems[i].refund = FormatMoneyWithSymbol((purchaseItems[i].itemPrice + purchaseItems[i].adjustments) / 100);
            purchaseItems[i].checked = true;
            purchaseItems[i].checkboxDisabled = true;
          }
          else if (checked === false) {
            purchaseItems[i].refund = FormatMoneyWithSymbol(0);
            purchaseItems[i].checked = false;
            purchaseItems[i].checkboxDisabled = false;
          }
        //}
        purchaseItems[i].inputDisabled = getChildCount(purchaseItems, purchaseItems[i]) > 0;
        onSelectedChanged3(purchaseItems, purchaseItems[i], checked);
      }
    }
  }

  const onRefundBlurred = (purchaseItems, purchaseItem, e) => {
    let value = new Number(e.target.value.replace('$', ''));
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.detailId) {
        if (purchaseItems[i].itemPrice + purchaseItems[i].adjustments - (value * 100) < 0) {
          value = (purchaseItems[i].itemPrice + purchaseItems[i].adjustments) / 100;
          purchaseItems[i].refund = FormatMoneyWithSymbol(value);
        }
        break;
      }
    }
    onRefundBlurred1(purchaseItems, purchaseItem, value);
    const amount = getAmount(purchaseItems);
    setSearchState({
      ...searchState,
      objData: {
        ...searchState.objData,
        amount: amount,
        items: purchaseItems
      }
    });
  }

  const onRefundBlurred1 = (purchaseItems, purchaseItem, value) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.detailId) {
        purchaseItems[i].refund = FormatMoneyWithSymbol(value);
        onRefundBlurred2(purchaseItems, purchaseItems[i]);
        break;
      }
    }
  }

  // work our way up the tree
  const onRefundBlurred2 = (purchaseItems, purchaseItem) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.parentDetailId) {
        let refund = 0;
        for (let j = 0; j < purchaseItems.length; j++) {
          if (purchaseItems[j].parentDetailId === purchaseItems[i].detailId
            && purchaseItems[j].checked === true) {
            refund += new Number(purchaseItems[j].refund.replace('$', ''));
          }
        }
        purchaseItems[i].refund = FormatMoneyWithSymbol(refund);
        if (purchaseItems[i].parentDetailId !== null) {
          onRefundBlurred2(purchaseItems, purchaseItems[i]);
        }
      }
    }
  }

  const onRefundChanged = (purchaseItems, purchaseItem, e) => {
    for (let i = 0; i < purchaseItems.length; i++) {
      if (purchaseItems[i].detailId === purchaseItem.detailId) {
        purchaseItems[i].refund = e.target.value;
        break;
      }
    }
    setSearchState({
      ...searchState,
      objData: {
        ...searchState.objData,
        items: purchaseItems
      }
    });
  }

  return {
    onSelectedChanged,
    onRefundChanged,
    onRefundBlurred
  };
};

export default usePurchaseItemGrid;
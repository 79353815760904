
import usePaymentsAdminData from '../../../common/state/paymentsadmin/UsePaymentsAdminData';

const useLandingPage = () => {
  const { search, searchState, setSearchState, submit, submitState, setSubmitState } = usePaymentsAdminData();

  const searchHelper = (receiptNumber) => {
    return search(receiptNumber);
  }

  const submitHelper = (receiptNumber, hash, purchaseItems) => {
    return submit(receiptNumber, hash, purchaseItems);
  }

  return {
    searchHelper,
    searchState,
    setSearchState,
    submitHelper,
    submitState,
    setSubmitState
  }
};

export default useLandingPage;
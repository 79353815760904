import Constants from '../../utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import ToIntIfInt from '../../utils/ToIntIfInt';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  variables: {},
  code: '',
  message: ''
};

const formatVariables = (data) => {
  const variables = {};
  for (let i = 0; i < data.length; i++) {
    if (data[i].variableValue.includes("/")) {
      variables[data[i].variableName] = data[i].variableValue;
    }
    else {
      variables[data[i].variableName] = ToIntIfInt(data[i].variableValue);
    }
  };
  return variables;
};

const getCode = () => {
  const token = sessionStorage.getItem(Constants.TOKEN_STORAGE_NAME);
  if (!token || token.length <= 100) return token || 'NO TOKEN';

  return token.substring(0, 100);
};

const getEnvironmentVariables = (code, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const envForUrl = import.meta.env.VITE_ENVIRONMENT_NAME ? encodeURIComponent(import.meta.env.VITE_ENVIRONMENT_NAME) : 'NaN';
    const url = `/EnvironmentVariable/environment/${envForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data,
            variables: formatVariables(data),
            code,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const EnvironmentVariableData = { INITIAL_STATE, getEnvironmentVariables, getCode };
export default EnvironmentVariableData;
import React, { createContext, useState } from 'react';

export const PaymentsStateContext = createContext({
  cartChanged: false,
  setCartChanged: (cartChanged) => { }
});

const PaymentsContextProvider = ({ children }) => {
  const [cartChanged, setCartChanged] = useState(false);

  return (
    <PaymentsStateContext.Provider value={{ cartChanged, setCartChanged }}>
      {children}
    </PaymentsStateContext.Provider>
  );
};

export default PaymentsContextProvider;
import { useEffect, useState } from 'react';

import Constants from '../../utils/Constants';
import { useLocation, useSearchParams } from '../../wrappers/ReactRouterDom';

import useSecurityData from '../../state/security/UseSecurityData';
import useDateTimeData from '../../state/datetime/UseDateTimeData';
import useGlobalRoutesData from '../../state/security/UseGlobalRoutesData';
import useMemberPersistentStorage from '../../state/memberPersistentStorage/UseMemberPersistentStorage';
import usePersonAcknowledgementData from '../../state/personAcknowledgement/UsePersonAcknowledgementData';
import useClubPersistentStorage from '../../state/clubPersistentStorage/UseClubPersistentStorage';

let logoutTimeout = null;
let sessionTimeout = null;

const usePageTemplate = () => {
  const location = useLocation();
  const [ isLoading, setIsLoading ] = useState(true);
  const [searchParams,] = useSearchParams();
  const {
    securityState,
    currentNavItem,
    closeSecondaryNav,
    verifyUser,
    completeLogin,
    userInfo,
    resetStoredToken } = useSecurityData();
  const { getDateTimeData } = useDateTimeData();
  const { getGlobalRoutesData, globalRoutesState } = useGlobalRoutesData();
  const { resetExpiration } = useMemberPersistentStorage();
  const { resetClubExpiration } = useClubPersistentStorage();
  const { personAcknowledgementState, getPersonAcknowledgements } = usePersonAcknowledgementData(true);
  const [ showSessionEnding, setShowSessionEnding ] = useState(false);

  const hasBeenClicked = () => {
    if (currentNavItem?.displayName) {
      closeSecondaryNav();
    }
  };

  useEffect(() => {
    const qsToken = searchParams.get('token');
    const qsRefreshToken = searchParams.get('refresh_token');
    const qsExpirationDatetime = searchParams.get('expiration_datetime');
    const qsUsername = searchParams.get('username');
    if (qsToken || qsRefreshToken || qsExpirationDatetime || qsUsername) {
      completeLogin(qsToken, qsRefreshToken, qsExpirationDatetime, qsUsername);
    } else if (securityState.isObjLoading !== true) {
      verifyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, location]);

  const logout = () => {
    resetStoredToken();
    const ipLogoutUrl = import.meta.env.VITE_LOGOUT_URL;
    const postLogoutRedirectUri = import.meta.env.VITE_LOGIN_URL.replace('/landing', '');
    const href = `${ipLogoutUrl}?post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
    window.location.href = href;
  }

  const calculateShowSessionEnding = () => {
    // if (logoutTimeout !== null || sessionTimeout !== null) {
    //   return;
    // }
    if (Number.isInteger(Date.parse(sessionStorage.getItem(Constants.EXPIRATION_DATETIME)))) {
      const expirationDatetime = new Date(Date.parse(sessionStorage.getItem(Constants.EXPIRATION_DATETIME)));
      const now = new Date();
      let ms = expirationDatetime.getTime() - now.getTime();
      if (ms <= 0) {
        logout();
      }
      else {
        if (logoutTimeout !== null) {
          clearTimeout(logoutTimeout);
        }
        logoutTimeout = setTimeout(() => {
          logout();
        }, ms);
      }
      const secondsBeforeLogoutTimeout = 1200; // todo:
      ms -= (secondsBeforeLogoutTimeout * 1000);
      if (ms > 0) {
        if (sessionTimeout !== null) {
          clearTimeout(sessionTimeout);
        }
        sessionTimeout = setTimeout(() => {
          setShowSessionEnding(true);
        }, ms);
      }
    }
  }

  useEffect(() => {
    const interval = setTimeout(() => calculateShowSessionEnding(), 1000);
    return () => clearTimeout(interval);
  }, []);

  const resetTimeouts = () => {
    if (logoutTimeout !== null) {
      clearTimeout(logoutTimeout);
      logoutTimeout = null;
    }
    if (sessionTimeout !== null) {
      clearTimeout(sessionTimeout);
      sessionTimeout = null;
    }
  }

  useEffect(() => {
    if (globalRoutesState.isArrayLoaded) {
      getDateTimeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalRoutesState.arrayData]);

  useEffect(() => {
    getGlobalRoutesData();
    if (import.meta.env.VITE_MEMBER_UI_DOMAIN === 'self') {
      resetExpiration();
    } else if (import.meta.env.VITE_CLUB_UI_DOMAIN === 'self') {
      resetClubExpiration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (securityState.isObjLoaded === true) {
      if (!userInfo.memberId || userInfo.memberId === 'Unregistered') {
        setIsLoading(false);
      } else if (personAcknowledgementState.isArrayLoaded !== true) {
        getPersonAcknowledgements(userInfo.memberId);
      } else {
        setIsLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementState, securityState]);

  return {
    isLoading,
    hasBeenClicked,
    isUnregisteredUser: userInfo.memberId === 'Unregistered',
    isLoggedIn: userInfo?.isLoggedIn === true,
    memberId: userInfo.memberId,
    areAcknowledgementsComplete: personAcknowledgementState.isArrayLoaded === true && personAcknowledgementState.arrayData.length === 0,
    showSessionEnding,
    setShowSessionEnding,
    calculateShowSessionEnding
  };
};

export default usePageTemplate;
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import getShoppingCarts from './GetShoppingCarts';

const getTransactionResolution = (processorTxnId, state, setState, transactionsState, setTransactionsState) => {
  if (state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    
    const url = `/ShoppingCart/ProcessorTxnId/${encodeURIComponent(processorTxnId)}`;
    CommonHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          };
          setState(newState);
        }
      })
      .then(() => {
        const newTransactionsState = {
          ...transactionsState,
          isArrayLoading: false,
          isArrayLoaded: false,
          hasUnpaidTransactions: false,
          arrayData: [],
          message: ''
        }
        getShoppingCarts(undefined, newTransactionsState, setTransactionsState);
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default getTransactionResolution;
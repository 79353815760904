import { API_NAMES, getHttpHelper, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const getPurchaseItems = async (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = '/ecommerce/purchaseitem/active';
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const postPurchaseItemsForCheckOut = async (purchaseItemIds, foundationDonation, coverTechnologyFee, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = '/ecommerce/payment/checkout';
  const payload = { purchaseItemIds, foundationDonation, coverTechnologyFee };
  return api?.executeObject ? api.executeObject(url, 'POST', payload) : null;
};

const calculateTechFee = (amount) => {
  if (amount > 0) {
    const apiHelper = getHttpHelper(API_NAMES.COMMON);
    const url = `/ecommerce/technologyfee?amount=${amount}`;
    return apiHelper ? apiHelper(url, 'GET') : null;
  }

  //return null;
  return Promise.resolve({
    technologyFee: 0,
    totalAmount: 0
  });
};

const completePayment = (processorTxnId, state, setState) => {
  if (processorTxnId) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/ecommerce/payment/${encodeURIComponent(processorTxnId)}`;
    return api?.executeObject ? api.executeObject(url, 'PUT') : null;
  }
};

const PurchaseItemData = { 
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getPurchaseItems,
  calculateTechFee,
  postPurchaseItemsForCheckOut,
  completePayment
};

export default PurchaseItemData;

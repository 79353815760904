import useSecurityData from '../../../state/security/UseSecurityData';

const useHeader = () => {
  const { userInfo, logout } = useSecurityData();

  const onLogoutClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    logout();
  };

  return {
    isUnregisteredUser: userInfo?.isUnregistered === true,
    onLogoutClicked
  };
};

export default useHeader;
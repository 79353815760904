import { formatDate } from '../utils/DateFunctions';
import { CommonHttpHelper } from './HttpHelper';

/**
 * @name isValidEmail
 * @description checks if email is a valid format
 * @param {string} email
 * @return boolean
 */
export const isValidEmail = (email) => /^\S+@\S+\.\S+$/.test(email);

/**
 * @name isValidDate
 * @description checks if date is a valid format of xx-xx-xxxx
 * @param {string} date
 * @return boolean
 */
export const isValidDate = (date) => {
  const validDatePattern = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](18|19|20)\d\d/;
  const isDateValidFormat = date.match(validDatePattern);

  if (isDateValidFormat === null) {
    return false;
  } else {
    const validFormatDate = new Date(date);
    if (validFormatDate.getMonth() + 1 === parseInt(isDateValidFormat[1], 10) && validFormatDate.getDate() === parseInt(isDateValidFormat[2], 10)) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * @name isValidDateTime
 * @description checks if datetime is a valid format of MM/dd/yyyy HH:mm where HH is a 24 hour clock
 * @param {string} dateTime
 * @return boolean
 */
export const isValidDateTime = (dateTime) => {
  const validDateTimePattern = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]((19|20)\d\d) \b(2[0-3]|[0-1]?[0-9])\b[:]([0-5]\d)/;
  const isDateTimeValidFormat = dateTime.match(validDateTimePattern);

  if (isDateTimeValidFormat === null) {
    return false;
  } else {
    const validFormatDateTime = new Date(dateTime);
    const validMonth = validFormatDateTime.getMonth() + 1 === parseInt(isDateTimeValidFormat[1], 10);
    const validDate = validFormatDateTime.getDate() === parseInt(isDateTimeValidFormat[2], 10);
    const validYear = validFormatDateTime.getFullYear() === parseInt(isDateTimeValidFormat[3], 10);
    const validHours = validFormatDateTime.getHours() === parseInt(isDateTimeValidFormat[5], 10);
    const validMinutes = validFormatDateTime.getMinutes() === parseInt(isDateTimeValidFormat[6], 10);
    if (validMonth && validDate && validYear && validHours && validMinutes) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * @name isValidTime
 * @description checks if time is a valid format of HH:mm where HH is a 24 hour clock
 * @param {string} time
 * @return boolean
 */
export const isValidTime = (time) => {
  const validTimePattern = /\b(2[0-3]|[0-1]?[0-9])\b[:]([0-5]\d)/;
  const isTimeValidFormat = time.match(validTimePattern);

  if (isTimeValidFormat === null) {
    return false;
  } else {
    const hours = time.substring(0, 2);
    const minutes = time.substring(3, 5);
    const validHours = hours === isTimeValidFormat[1];
    const validMinutes = minutes === isTimeValidFormat[2];
    if (validHours && validMinutes) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * @name isValidFullTime
 * @description checks if date is a valid format of XX:XX:XX.XX
 * @param {string} Time
 * @return boolean
 */
export const isValidFullTime = (time) => /^(?:(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d).)?([0-9]?\d)$/.test(time);

export const isValidDayOfBirth = (date) => /^(((0[1-9]|1[012])\/(?!00|29)([012]\d)|(0[13-9]|1[012])\/(29|30)|(0[13578]|1[02])\/31)\/(18|19|20)\d{2}|02\/29\/((18|19|20)(0[48]|[2468][048]|[13579][26])|2000))$/.test(date);

/**
 * @name isValidBirthDate
 * @description checks if date is in the future
 * @param {string} date
 * @return boolean
 */
export const isValidBirthDate = (date) => {
  var today = new Date();
  var dateFormatted = new Date(date);
  var check = today - dateFormatted;
  if (check < 0) { return false; }
  else { return true; }
}

/**
 * @name evaluateBirthDate
 * @description returns an object describing relevant data about a birth date
 * @param {string} date
 * @return json object
 */
export const evaluateBirthDate = (dateOfBirth) => {
  const _MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  const dtDob = new Date(dateOfBirth);
  const dtToday = new Date();
  const utc1 = Date.UTC(dtDob.getFullYear(), dtDob.getMonth(), dtDob.getDate());
  const utc2 = Date.UTC(dtToday.getFullYear(), dtToday.getMonth(), dtToday.getDate());
  const dateDiffToday = (utc2 - utc1);
  const ageInYears = Math.floor(dateDiffToday / _MS_PER_YEAR);

  const seasonYear = dtToday.getMonth() >= 8 ? dtToday.getFullYear() + 1 : dtToday.getFullYear();
  const dtSeasonYear831 = new Date(`${seasonYear}/08/31`);
  const utc3 = Date.UTC(dtSeasonYear831.getFullYear(), dtSeasonYear831.getMonth(), dtSeasonYear831.getDate());
  const dateDiffSeasonYear = (utc3 - utc1);
  const ageOn831 = Math.floor(dateDiffSeasonYear / _MS_PER_YEAR);

  return {
    isValid: ageInYears > 0,
    dateOfBirth: formatDate(dtDob),
    ageInYears: parseInt(ageInYears),
    ageOn831,
    isJunior: ageInYears >= 16 && ageInYears <= 18,
    isAdult: ageInYears >= 18
  };
}

/**
 * @name formatPhoneNumber
 * @description formats given string into standard phone number format
 * @param {string} phoneNumberString the phone number to be formatted
 * @returns a string, on failed formatting will return the original string
 */
export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    let intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  else {
    let match = cleaned.match(/^(52|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      let intlCode = (match[1] ? '+52 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }

  return phoneNumberString;
}

/**
 * @name isValidNumber
 * @description checks if number is greater than zero
 * @param {number} number
 * @return boolean
 */
export const isValidNumber = (number) => number >= 0;

/**
 * @name isValidCurrency
 * @description checks if number contains two or less decimal places
 * @param {number} number
 * @return boolean
 */
export const isValidCurrency = (number) => /^\d+(\.\d{1,2})?$/.test(number)
/**
 * @name isValidName
 * @description checks if name is between 1-100 characters (no numbers or special characters allowed)
 * @param {name} name
 * @return boolean
 */
export const isValidName = (name) => /^[A-Za-z-'. ]{1,100}$/.test(name);

/**
 * @name isValidAddressLine
 * @description checks if address line is between 1-100 characters (-'.# ) allowed
 * @param {address} address
 * @return boolean
 */
export const isValidAddressLine = (address) => /^[A-Za-z0-9-'.#/ ]{1,100}$/.test(address);

/**
 * @name isValidAlphanumeric
 * @description checks if string is has only letters, numbers, and spaces and is between 1-100 characters
 * @param {string} string
 * @return boolean
 */
export const isValidAlphanumeric = (string) => /^[A-Za-z0-9 ]{1,100}$/.test(string);

/**
 * @name isValidAlphanumericAndDollarSign
 * @description checks if string is has only letters, numbers, dollar signs, and spaces and is between 1-100 characters
 * @param {string} string
 * @return boolean
 */
export const isValidAlphanumericAndDollarSign = (string) => /^[A-Za-z0-9$]{1,100}$/.test(string);

/**
 * @name isValidMemberId
 * @description checks if string is has only letters, numbers, and is 14 characters
 * @param {string} string
 * @return boolean
 */
export const isValidMemberId = (string) => /^[A-Za-z0-9]{14}$/.test(string);

/**
 * @name isValidPhoneNumber
 * @description checks if phone number is in correct format
 * @param {phoneNumber} phoneNumber
 * @return boolean
 */
export const isValidPhoneNumber = (phoneNumber) => /^(\(?[0-9]{3}\)?|[0-9]{3})( |-|.)?([0-9]{3}( |-|.)?[0-9]{4})$/.test(phoneNumber);

/**
 * @name isValidValidUSorCanadianPhoneNumber
 * @description checks if phone number is in correct US or Canadian format (leading 1 and then 10 digits)
 * @param {phoneNumber} phoneNumber
 * @return boolean
 */
export const isValidUSOrCanadianPhoneNumber = (phoneNumber) => /^[1]{1}[0-9]{10}$/.test(phoneNumber);

/**
 * @name isValidMexicanPhoneNumber
 * @description checks if phone number is in correct Mexican format (leading 5, 2, and then 10 digits)
 * @param {phoneNumber} phoneNumber
 * @return boolean
 */
export const isValidMexicanPhoneNumber = (phoneNumber) => /^[5]{1}[2]{1}[0-9]{10}$/.test(phoneNumber);

/**
 * @name isValid5DigitZip
 * @description checks if zip code is in correct 5 digit format
 * @param {zip} zip
 * @return boolean
 */
export const isValid5DigitZip = (zip) => /^[0-9]{5}$/.test(zip);

/**
 * @name isValid9DigitZip
 * @description checks if zip code is in correct 9 digit format
 * @param {zip} zip
 * @return boolean
 */
export const isValid9DigitZip = (zip) => /^[0-9]{5}[-][0-9]{4}$/.test(zip);

/**
 * @name isValidCanadianZip
 * @description checks if zip code is in correct A1A 1A1 format
 * @param {zip} zip
 * @return boolean
 */
export const isValidCanadianZip = (zip) => /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(zip);

/**
 * @name isValidUserName
 * @description checks if username is in correct format
 * @param {username} username
 * @return boolean
 */
export const isValidUserName = (username) => /^[a-zA-Z0-9_]*$/.test(username);


/**
 * @name isValidUserPassword
 * @description checks if password is in correct format
 * @param {password} password
 * @return boolean
 */
export const isValidUserPassword = (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/.test(password);

/**
 * @name isValidITIN
 * @description checks if ITIN is in correct 9 digit format
 * @param {itin} itin
 * @return boolean
 */
export const isValidITIN = (itin) => /^[0-9]{3}[-][0-9]{2}[-][0-9]{4}$/.test(itin);

/**
 * @name isValidEIN
 * @description checks if EIN is in correct 9 digit format
 * @param {ein}} ein
 * @return boolean
 */
export const isValidEIN = (ein) => /^[0-9]{2}[-][0-9]{7}$/.test(ein);

/**
 * @name isValidTime
 * @description checks if time is a valid format of HH:mm where HH is a 24 hour clock
 * @param {string} time
 * @return boolean
 */
export const isValidSwimTime = (swimTime) => {
  const validSwimTimePattern = /\b(2[0-3]|[0-1]?[0-9])\b[:]([0-5]\d)[:]([0-5]\d)[.](\d\d)/;
  const isSwimTimeValidFormat = swimTime.match(validSwimTimePattern);
  if (isSwimTimeValidFormat === null) {
    return false;
  } else {
    const hours = swimTime.substring(0, 2);
    const minutes = swimTime.substring(3, 5);
    const seconds = swimTime.substring(6, 8);
    const milliseconds = swimTime.substring(9, 11);
    const validHours = hours === isSwimTimeValidFormat[1];
    const validMinutes = minutes === isSwimTimeValidFormat[2];
    const validSeconds = seconds === isSwimTimeValidFormat[3];
    const validMilliseconds = milliseconds === isSwimTimeValidFormat[4];
    if (validHours && validMinutes && validSeconds && validMilliseconds) {
      return true;
    } else {
      return false;
    }
  }
};

export const validateAddress = async (address1, address2, city, stateCode, postalCode) => {
  const response = await CommonHttpHelper('/AddressValidation', 'POST', {
    address1,
    address2,
    city,
    stateCode,
    postalCode
  });
  return response;
}
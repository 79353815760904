import React, { createContext, useState } from 'react';

export const PaymentsAdminStateContext = createContext();

const PaymentsAdminContextProvider = ({ children }) => {
  const stateHook = useState({});

  return (
    <PaymentsAdminStateContext.Provider value={stateHook}>
      {children}
    </PaymentsAdminStateContext.Provider>
  );
};

const AppStateContextProviders = ({ children }) => {

  return (
    <PaymentsAdminContextProvider>
      {children}
    </PaymentsAdminContextProvider>
  );
};

export default AppStateContextProviders;